<template>
      <div class="models row flex-column flex-flow-row" :class="{'scrolling': !scrolledToLeft}">
            <div :class="[fillRow? 'col-6' : 'col-12 xsm:col-10 sm:col-6 lg:col-4 xl:col-3 ']" class="single-model cursor-pointer" v-for="(items, i) in groupedItems" :key="i">
                  <Floor @click="handleCardClick" :item="{...item, index: j === 0 ? (( i + 1) * 2) - 1 : (i + 1) * 2}" :class="{'mt-20': j%2 !== 0 }" v-for="(item, j) in items" :key="j"/>
            </div>
            <!-- <div v-show="!groupedItems.length && !isLoading" class="no-items d-flex justify-content-center">
                  <img class="no-item" v-lazy-src="require(`@/assets/images/desktop/svg/no-item.svg`)" alt="no-item">
                  <span>
                        No data Found!
                  </span>
            </div> -->
      </div>
</template>

<script>
      import Floor from '@/components/common/floorplans/floor';
      import floorMixins from '@mixins/floorMixins' 
      import { bus } from '@m'
      import directive from '@/directives/lazy-src';

export default {
      name: "single-floor-xl",
      directives: {directive},
      components: { Floor },
      mixins: [floorMixins],
      data(){
            return {
                  groupedItems: [],
                  filteringData: null
            }
      },
      computed: {
            isLoading(){
                  return this.$store.state.isLoading;
            }
      },
      props: {
            fillRow: {
                  type: Boolean,
                  default: ()=>{}
            }
      },
      methods: {
            doScroll(e) {
                  const deltaX = Math.max(-1, Math.min(1, e.deltaX));
                  if(deltaX === 1) {
                          document.querySelector('.models').scrollLeft += 20;

                  } else if(deltaX === -1) {
                        document.querySelector('.models').scrollLeft += -20;
                  }
            },
            groupItems() {
                  let result = [];
                  let floors = this.floorPlans.slice();
                  for (let i = floors.length / 2; i > 0; i--) {
                        result.push(floors.splice(0, Math.ceil(floors.length / i)));
                  }
                  this.groupedItems = result;
            },
            filterItems(data) {
                  console.log(this.floorPlans)
                  this.filteringData = data;
                  let floors = this.floorPlans.slice();
                  let vm = this;
                        console.log(floors)
                  let filteredItems = floors.filter(function (item) {
                        if (item.size > vm.filteringData.min && item.size < vm.filteringData.max) {
                              return  (vm.filteringData.bedrooms?.length ? vm.filteringData.bedrooms.includes(item.bedroom) : true) && (vm.filteringData.bathrooms?.length ? vm.filteringData.bathrooms.includes(item.bathroom) : true)
                        }
                  })
                  console.log(filteredItems)
                  this.groupedItems = [];
                  for (let i = filteredItems.length / 2; i > 0; i--) {
                        this.groupedItems.push(filteredItems.splice(0, Math.ceil(filteredItems.length / i)));
                  }
            },
            setItemsByName(val){
                  if(!val) {
                        this.groupItems()
                        return
                  }
                  let floors = this.floorPlans.slice();
                  floors.sort((a, b)=> {
                        if ( a.name.replace(/[0-9]/g, '') < b.name.replace(/[0-9]/g, '') ){
                              return -1;
                        }
                        if ( a.name.replace(/[0-9]/g, '') > b.name.replace(/[0-9]/g, '') ){
                              return 1;
                        }
                        return 0;
                  }).sort((a, b)=> {
                        if((a.name.replace(/[0-9]/g, '') !== b.name.replace(/[0-9]/g, ''))) return 0
                        if ( parseInt(a.name.replace( /^\D+/g, '')) < parseInt(b.name.replace( /^\D+/g, '')) ){
                              return -1;
                        }
                        if ( parseInt(a.name.replace( /^\D+/g, '')) > parseInt(b.name.replace( /^\D+/g, '')) ){
                              return 1;
                        }
                        return 0;
                  })
                  let filteredItems = floors.filter(function (item) {
                        return item.name.includes(val)
                  })
                  this.groupedItems = [];
                  for (let i = filteredItems.length / 2; i > 0; i--) {
                        this.groupedItems.push(filteredItems.splice(0, Math.ceil(filteredItems.length / i)));
                  }
            },
            handleCardClick(item){
                  if(this.$route.params.id) {
                        this.$emit('setFloorItem', item)
                        return
                  }
                  this.$router.push({path: `/floorplans/${item.id}`})
            }
      },
      mounted(){
            // window.addEventListener("wheel", this.doScroll, false);
            // window.addEventListener('touchmove', function (e) {
            //       // stop touch event
            //       // e.stopPropagation();
            //       e.preventDefault();

            //       this.doScroll(e)
            // }, false);
      },
      created(){
            bus.$on('reset', this.groupItems)
            bus.$on('filterItemsByName', this.setItemsByName);
      },
      beforeDestroy(){
            window.removeEventListener("wheel", this.doScroll)
            window.removeEventListener("touchmove", this.doScroll)
      }
};
</script>

<style lang='scss'>
@import 'SingleFloorXL';
</style>