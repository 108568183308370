import { bus } from '@m';
import { getUnits } from "@api/axios/unit.service";
const floorMixins = {
      data() {
            return {
                  scrolledToLeft: false,
                  floorPlans: []
            }
      },
     
      methods: {
            actionScroll() {
                  const myDiv = document.querySelector('.models');
                  (myDiv.offsetWidth + myDiv.scrollLeft) >= myDiv.scrollWidth ? this.scrolledToLeft = true : this.scrolledToLeft = false
            },
            addScroll() {
                  const componentName = this.$options.name;
                  componentName === 'single-floor-xl' ? this.groupItems() : ''
                  const wrapper = document.querySelector('.models');
                  wrapper.addEventListener('scroll', this.actionScroll);
                  // wrapper.addEventListener('touchmove', function (e) {
                  //       // stop touch event
                  //       // e.stopPropagation();
                  //       e.preventDefault();
                  //       wrapper.scrollLeft += e.deltaY;
                  // }, false);
                  // wrapper.addEventListener("wheel", (evt) => {
                  //       evt.preventDefault();
                  //       wrapper.scrollLeft += evt.deltaY;
                  // });
            },
            initialize(){
                  getUnits().then((res) => {
                        this.floorPlans = res.data.data;
                        this.addScroll()
                  }).catch(e => {
                        console.log(e);
                  })
            },
            handleCardClick(item) {
                  this.goTo(`/floorplans/${item.id}`);
            }
      },
      mounted() {
            this.initialize()
      },
      beforeDestroy() {
            document.querySelector('.models')?.removeEventListener('scroll', ()=> {});
            document.querySelector('.models')?.removeEventListener('wheel', () => {});
      },
      created() {
            bus.$on('filter', this.filterItems)
      }
}

export default floorMixins;