<template>
      <div class="unique-floor">
            <card
                  @click="$emit('click', item)"
            >
                  <template v-if="hasHeader" slot="cardHeader">
                        <div class="card-fix-header" :class="{'full-width d-flex justify-content-between': $slots.headerAction}">
                              <span>{{ item.name }}</span>
                              <slot name="headerAction"></slot>
                        </div>
                  </template>
                  <div class="model-image">
                        <img
                              :class="{'has-error': imgHasError}"
                              @error="errorHandler()"
                              onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/3342/3342137.png'"
                              :src="item.image"
                              :alt="item.name"
                        />
                        <!-- <img
                              :src="require(`@a/images/desktop/svg/pages/floorplan/${item.name}.svg`)"
                              :alt="item.name"
                        /> -->
                  </div>
                  <div class="model-details">
                        <p class="d-flex justify-content-center floor-detail">
                              <img src="@a/icons/svg/resize.svg" alt="resize" />
                              <span class="ml-15">{{ item.size }} sq.ft</span>
                        </p>
                        <p class="d-flex justify-content-center floor-detail">
                              <img src="@a/icons/svg/room.svg" alt="room" />
                              <span class="ml-15">{{ item.bedroom }}</span>
                        </p>
                        <p class="d-flex justify-content-center floor-detail">
                              <img src="@a/icons/svg/shower.svg" alt="shower" />
                              <span class="ml-15">{{ item.bathroom }}</span>
                        </p>
                  </div>
            </card>
      </div>
</template>

<script>
      import Card from '@b/card/Card';
      export default {
            name: "floor",
            components: { Card },
            data(){
                  return {
                        imgHasError: false
                  }
            },
            props:{
                  item: {
                        type: Object,
                        default : () => {}
                  },
                  hasHeader:{
                        type: Boolean,
                        default: true
                  }
            },
            methods: {
                  errorHandler(){
                        this.imgHasError = true;
                  }
            }
      };
</script>

<style lang='scss'>
@import "floor";
.model-details {
      p{
            width: 33%;
      }
}
.unique-floor.full-width{
      .model-details {
            p{
                  width: 150px!important;
            }
      }
}
</style>