<template>
      <div @click="$emit('click')" class="card" :style="style">
            <div v-if="title || $slots.cardHeader" class="card-header">
                  <slot v-if="$slots.cardHeader" name="cardHeader"></slot>
                  <span class="title" v-else-if="title">{{title}}</span>
            </div>
            <div class="card-body">
                  <slot/>
            </div>
      </div>
</template>

<script>
      export default {
            name: 'card',
            props: {
                  title: {
                        type: String,
                        default: ""
                  },
                  bgColor: {
                        type: String,
                        default: "#ffffff1a"
                  }
            },
            computed: {
                  style(){
                        return `background-color: ${this.bgColor}`
                  }
            }
      }
</script>

<style lang='scss'>
@import 'Card';
</style>